$placeholder-list:
	"::-webkit-input-placeholder",
	"::-moz-placeholder",
	":-ms-input-placeholder",
	"::-ms-input-placeholder",
	":placeholder-shown";

@mixin placeholder($color: "inherit") {
	@each $placeholder in $placeholder-list {
		#{$placeholder} {
			@content;
		}
	}
}

@mixin button-grey() {
    border: 1px solid $theme-font-white;
    color: $theme-font-white;

    @include disabled {
        background-color: darken($theme-font-white, 10);
    }

    svg {
        fill: $theme-font-white;
    }
}

@mixin button-green() {
    background-color: $theme-green;
    border: 1px solid $theme-green;
    color: $white;

    @include disabled {
        background-color: darken($theme-green, 10);
    }

    svg {
        fill: $white;
    }
}

@mixin button-red() {
    background-color: $theme-red;
    border: 1px solid $theme-red;
    color: $white;

    @include disabled {
        background-color: darken($theme-red, 10);
    }

    svg {
        fill: $white;
    }
}

@mixin button-clear() {
    background-color: transparent;
    border: 1px solid $theme-font-white;
    color: $theme-blue;

    @include disabled {
        background-color: darken($theme-font-white, 10);
    }
}

@mixin button-dark-blue() {
    background-color: transparent;
    border: 1px solid $theme-font-white;
    color: $theme-blue__light;

    @include disabled {
        background-color: darken($theme-blue__light, 10);
    }

    svg {
        fill: $theme-blue__light;
    }
}

@mixin button-dark-blue-inverted() {
    background-color: $theme-blue__light;
    border: 1px solid $theme-blue__light;
    color: $white;

    @include disabled {
        background-color: darken($theme-blue__light, 10);
    }

    svg {
        fill: $white;
    }
}

@mixin button-blue() {
    background-color: $theme-blue;
    border: 1px solid $theme-blue;
    color: $white;

    @include disabled {
        background-color: darken($theme-blue, 10);
    }

    svg {
        fill: $white;
    }
}

@mixin button-blue-extra-10() {
    background-color: darken($theme-blue, 10);
    border: 1px solid darken($theme-blue, 10);
    color: $white;

    @include disabled {
        background-color: darken($theme-blue, 20);
    }

    svg {
        fill: $white;
    }
}

@mixin button-no-border() {
    background-color: transparent;
    border: none;
    color: $theme-grey;

    @include disabled {
        background-color: darken($theme-font-white, 10);
    }
}


@mixin banner-overlay() {
    @include before {
        background-color: rgba($theme-blue__overlay, .70);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: $theme-z-index-medium;
    }
}

@function banner-image($id, $size) {
    $images: (
        1: '/storage/banners/#{$size}/1.jpeg',
        2: '/storage/banners/#{$size}/2.jpeg',
        3: '/storage/banners/#{$size}/3.jpeg',
        4: '/storage/banners/#{$size}/4.jpeg',
        5: '/storage/banners/#{$size}/5.jpeg',
        6: '/storage/banners/#{$size}/6.jpeg',
        7: '/storage/banners/#{$size}/7.jpeg',
        8: '/storage/banners/#{$size}/8.jpeg',
        9: '/storage/banners/#{$size}/9.jpeg',
        10: '/storage/banners/#{$size}/10.jpeg',
        11: '/storage/banners/#{$size}/11.jpeg',
        12: '/storage/banners/#{$size}/12.jpeg',
        13: '/storage/banners/#{$size}/13.jpeg',
        14: '/storage/banners/#{$size}/14.jpeg',
        15: '/storage/banners/#{$size}/15.jpeg',
        16: '/storage/banners/#{$size}/16.jpeg',
        17: '/storage/banners/#{$size}/17.jpeg',
    );

    @if map-has-key($images, $id) {
        @return map-get($images, $id);
    }

    @return map-get($images, 1);
}

@mixin text-style() {
    font-size: rem-calc(14);
    color: $theme-grey__dark;
}

@mixin label-styles() {
    @include text-style();
    display: block;
    text-transform: none;
    letter-spacing: 1.17px;
    padding-bottom: $global-margin / 2;
}

@mixin form-input-styles() {
    @include text-style();
    font-size: 1rem;
    display: block;
    background-color: $white;
    padding: rem-calc(14.5);
    outline: 0;
    border-radius: $theme-border-radius;
    border: 1px solid $theme-font-white;
    line-height: $global-margin;
}

@mixin button-elements() {
	input,
	a,
	button {
		@content;
	}
}

@mixin hocus {
	&:focus,
	&:hover {
		@content;
	}
}

@mixin hocus-active {
    &:focus,
	&:hover,
    &.active {
		@content;
	}
}

%ba {
	content: "";
	display: block;
}

@mixin before {
	&::before {
		@extend %ba;
        @content;
	}
}

@mixin after {
	&::after {
		@extend %ba;
        @content;
	}
}

@mixin disabled {
    &:disabled {
        @content;
    }
}

@mixin active {
    &.active {
        @content;
    }
}

@mixin checked {
    &:checked {
        @content;
    }
}
