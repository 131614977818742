.chat {
    .chat__messages {
        border: 1px solid $theme-font-white;
        padding: rem-calc(0 14.5);
        height: rem-calc(200);
        overflow-y: scroll;
        border-radius: $theme-border-radius;

        li {
            padding-top: rem-calc(14.5);
            padding-bottom: rem-calc(14.5);

            p {
                border-radius: $theme-border-radius;
                padding: rem-calc(10);
                display: inline-block;
                max-width: 50%;
                word-wrap: break-word;

                &.mine {
                    background-color: $theme-blue;
                    color: $white;
                    float: right;
                }

                &.theirs {
                    background-color: $theme-grey;
                    color: $theme-black;
                    float: left;
                }
            }

            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    .chat__messages--large {
        height: rem-calc(300);
    }

    .chat__form {
        textarea {
            min-height: rem-calc(75);
        }
    }
}
