.pretty-lists {
    list-style: none;
    list-style-image: url('../../svg/bullet-blue.svg');

    .pretty-lists {
        padding-top: .5rem;
    }

    li {
        @include text-style();
        position: relative;
        padding-bottom: 0.5rem;

        > a,
        > p {
            padding-left: rem-calc(8);
            margin: 0;
            position: relative;
            top: 1px;
        }

        a:not(:hover),p:not(:hover) {
            font-size: inherit;
            color: inherit;
        }
        
        .pad-lt{
            padding-left: rem-calc(16);
        }
    }
}

.status-lists {
    list-style: none;
    
    ul{
        list-style: none;
    }
    
    .icon__svg{
        fill: $theme-green;
    }
}
