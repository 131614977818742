.title {
    .title__content {
        margin-top: rem-calc(27.5);
    }

    img {
    	margin: 0 auto;
    	display: block;
    }
}


@include breakpoint(medium) {
	.title {
		.title__content {
	        margin-top: 0;
	    }

	    img {
	    	margin: 0;
	    	display: inline-block;
	    }
	}
}