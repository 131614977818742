.checkbox {
    input[type="checkbox"] {
        opacity: 0;
    }

    
    label {
        position: relative;
        display: inline-block;
        padding-left: 12px;

        &:disabled{
           cursor: none;
        }
        
        &:hover{
           cursor: pointer; 
        }

        &::before{
            position: absolute;
            content: "";
            display: inline-block;
            height: 16px;
            width: 16px;
            border: 1px solid;
            left: 0px;
            top: -11px;
        }

        &::after{
            position: absolute;
            content: "";
            display: inline-block;
            height: 5px;
            width: 17px;
            border-left: 2px solid;
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            left: 3px;
            top: -7px;
        }
    }

    

    input[type="checkbox"] + label::after {
        content: none;
    }
    input[type="checkbox"]:checked + label::after {
        content: "";
    }
    input[type="checkbox"]:focus + label::before {
        outline: rgb(59, 153, 252) auto 5px;
        
    }

    &.new{
        // border: 1px solid red;
        width: 25px;
        height: 25px;
        display: inline-flex;
        margin-right: 0.5em;

        input[type="checkbox"]{
            position:absolute;
            left:0;
            top:0;
        }
        label{
            position: relative;;
            padding-left:0;

            &::before{
                height: 22px;
                width: 22px;
                border: 1px solid #ddd; 
                border-radius:50%;
                top:-14px;
                left:1px;
                background:#ddd;
                position:static;
             }
         
             &:after{
                 height: 7px;
                 width: 12px;
                 border-left: 3px solid#7ED321;
                 border-bottom: 3px solid#7ED321;
                left: 0;
                top: -3px;
                bottom: 0;
                right: 0;
                margin: auto;
               
             }
        }

        input[type="checkbox"]:checked + label{
            &::before{
                background:#fff;
            }
        }
       
        input[type="checkbox"]:focus + label::before {
            outline: none;
    
        }
    }
    
}

.arrowsvg .icon__svg {
    margin: 0rem !important;
  }
  .notifyall-btn{
    margin-top:29px;
  }
  .smallgap-2 {
    flex: 0 0 16.6666666667%;
    max-width: 11.667%;
  }
  .parent-heading{
    border-bottom:none;
  }
 .fclr {
    color:#ababab;
} 
.btn-spce{
    margin-left: 25px;
}
  .pretty-lists.status-lists{
      li{
          display:flex;
          align-items: center;
          margin-bottom:0.5em;

          > span{
            display:flex !important;
            align-items: center;
          }
        
          form{
            display: flex !important;
        }
          
      }
  }

//   &:last-child{
//     >span{
//       position: relative;
//       &::before{
//           content:"";
//           height: 22px;
//           width: 22px;
//           border: 1px solid #ddd; 
//           border-radius:50%;
//           top:-14px;
//           left:1px;
//           background:#ddd;
//           position:static;
//        }
//     }
  
// }

  .tick-wrapper{
    background: #7ED321;
    width: 22px;
    height: 22px;
    display: inline-flex;  
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.5em;


    svg{
        fill: white !important;
        display:none !important;
    }
    &:after{
        content:"";
        height: 7px;
        width: 12px;
        border-left: 3px solid#fff;
        border-bottom: 3px solid#fff;
        transform: rotate(-45deg);
       left: 0;
       top: -3px;
       bottom: 0;
       right: 0;
       margin: auto;
      
    }

    &.dimmed{
        background: #eee;
        &:after{
            display:none;
        }
    }
  }

  .sub-list{
    border: 1px solid #eee;
    margin: 0;
    padding: 1em 1em 0 1em;
    display: inline-flex;
    li{
        margin-right: 3em;
        display: flex !important;
        align-items: center !important;
        padding: 0 !important;
        margin-bottom: 1em !important;

        &:last-child{
            margin-right: 0em;
        }

        form{
            display: flex !important;
        }
    }
  }