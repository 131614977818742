.sidebar {
    display: none;
    box-shadow: 0 2px 10px 0 rgba(25,63,78,0.25);

    > .row {
        border-bottom: 1px solid $theme-grey;
        padding-bottom: $global-margin * 2;
        padding-top: $global-margin * 2;
    }

    &-container {
        padding: rem-calc(16);
    }
}


@media #{breakpoint(large)} {
    .sidebar {
        display: block;
        width: rem-calc(245);
        padding: rem-calc(16);

        .profile-block__button {
            margin-bottom: rem-calc(16);
        }
    }
}
