.service-plan {
    border-radius: rem-calc(4);
    padding: 0 0 rem-calc(16); 
    margin-bottom: 0;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    $padding: 15px;

    &__title {
        background-color: $theme-blue;
        padding: rem-calc(20) rem-calc($padding);
        color: $white;
        border-top-right-radius: rem-calc(4);
        border-top-left-radius: rem-calc(4);

        &--plain {
            background-color: $theme-blue__light;
            padding: rem-calc(32) rem-calc($padding);
        }
    }

    &__highlight {
        font-size: rem-calc(14);
    }

    &__heading {
        margin: 0;
        line-height: 1;
    }

    &__features {
        padding: rem-calc(21) rem-calc($padding);
 
        p {
            margin: 0;
            font-size: rem-calc(14);;
            color: $theme-grey__dark;
        }
    }

    &__advisory {
        padding: rem-calc(18) rem-calc($padding);
        border-top: 1px dotted $theme-gray-alt;
        border-bottom: 1px dotted $theme-gray-alt;
        color: $theme-blue-alt;
        font-size: rem-calc(14);


        p {
            margin: 0;
            color: inherit;
            font-size: inherit;
        }
    }

    &__price-monthly {
        color: $theme-grey__dark;
        padding: rem-calc(16) rem-calc($padding) 0;

        p {
            font-size: rem-calc(14);
            margin: 0;
        }

        span {
            font-size: rem-calc(24);
        }
    }

    &__price-yearly {
        color: $theme-grey__dark;
        margin-bottom: rem-calc(2);
    }

    &__button {
        padding: 0 rem-calc($padding);
    }

    &__matrix {
        color: $theme-grey__dark;
        display: table;
        padding-top: rem-calc(12);

        svg {
            fill: $theme-blue-alt-2;

            &.icon__cross {
                transform: rotate(45deg);
            }
        }

        thead {
            background: none;
            border: 0;

            h4 {
                color: $theme-grey__dark;
            }
        }

        tbody {
            border: 0;

            tr {
                background-color: transparent;

                &:last-child td {
                    border-bottom: 0;
                }
            }

            td {
                text-align: right;
                border-bottom: 1px dotted $theme-gray-alt;
                border-spacing: 0;
                padding-top: rem-calc(11);
                padding-bottom: rem-calc(11);
            }
        }

        &--text-left {
            text-align: left !important;
        }
    }
}