.auth {
    &.auth__bg {
        padding-top: $global-margin;
        padding-bottom: $global-margin * 2;
        background-image: radial-gradient(0% 75%, #1782AD 0%, $theme-blue__light 67%);
        background-image: -webkit-radial-gradient(0% 75%, #1782AD 0%, $theme-blue__light 67%);
    }

    .auth__width {
        max-width: rem-calc(625);
    }

    .auth__svg {
        margin: 0 auto;

        svg {
            fill: $white;
        }
    }
}
