.pagination {
    li {
        padding: rem-calc(10);

        a,
        span {
            display: block;
            padding: 0 rem-calc(5);
        }
    }
}