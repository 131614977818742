table {
	max-width: calc(100vw - 54px);
	overflow-x: scroll;
	display: block;

	.spacing-large {
		display: block;
		width: rem-calc(200);
	}
}


.table-scroll {
	display: block;
	max-width: calc(100vw - 96px);
	
	&.content-table {

		table {
			min-width: 100%;
			border-collapse: collapse;
			display: table;
			max-width: 100%;

			thead {
				border: 0;
				background: transparent;

				th {
				    border-bottom: rem-calc(1) dotted $theme-font-white;
				}
			}

			tbody {
				border: 0;

				tr {
					background: transparent;

					&.even {
						background: $theme-grey;
					}
				}

				td {
					border: 0;

					&.table__bool svg {
			            fill: $theme-blue;
					}

					p {
						margin-bottom: 0;
					}
				}

			}

			td,
			th {
				@include label-styles();
				display: table-cell;
				font-weight: normal;
				padding: rem-calc(8) rem-calc(12);
			}
		}
	}

	&.finance-table {
		max-width: calc(100% + 34px);
		width: calc(100% + 34px);
		margin-left: rem-calc(-34);

		thead {
			tr th {
				padding: 0;
				border-bottom: 0;

				&:first-of-type {
					min-width: rem-calc(36);
				}

				label {
					padding-bottom: 0;
				}
			}
		}

		tbody tr {

			&:first-of-type td {
				padding-top: rem-calc(8);
				min-width: rem-calc(36);
			}

			td {
				padding: rem-calc(6);
				position: relative;
			}

			.input-div {
				padding: rem-calc(14) rem-calc(6);
			}

			.icon.icon--close__negative {
				position: static;
				top: inherit;
				left: inherit;
				padding: rem-calc(3);
				width: rem-calc(16);
				height: rem-calc(16);
				margin: 0 auto;

				svg {
					width: rem-calc(8);
					height: rem-calc(8);
				}
			}

			.icon-col {
				padding: 0 rem-calc(4) 0;
			}

			.financial-information__align .input-div {
			    border: 1px solid $theme-font-white;
			    border-radius: 4px;
			    min-height: rem-calc(50);

			    svg {
			    	margin-top: rem-calc(6);
			    }
			}
		}
	}
}

@include breakpoint(medium) {
	.table-scroll {

		&.finance-table {
			tbody tr {

				.input-div {
					padding: rem-calc(16);
				}

				.financial-information__align {
				    border: 0;

				    .input-div {
				    	border: 0;
				    }
				}
			}

		}

	}

}