.social-login {
    a {
        padding: rem-calc(16) rem-calc(16) rem-calc(16) rem-calc(8);
        border: rem-calc(2) solid $white;
        border-radius: rem-calc(4);
        line-height: rem-calc(46);
    }

    &--google {
        color: $white;
        font-weight: bold;

        &::before {
            content: url(/storage/social/btn_google_light_normal_ios.svg);
            height: rem-calc(46);
            display: inline-block;
            margin-right: rem-calc(16);
            vertical-align: middle;
        }

        @include hocus {
            color: map-get($foundation-palette, primary);
            border: rem-calc(2) solid map-get($foundation-palette, primary);
            background: $white;
        }
    }

    &--linkedin {
        color: $white;
        font-weight: bold;
        border: rem-calc(2) solid $white;
        border-radius: rem-calc(4);
        

        &::before {
            background: url(/storage/social/In-2CRev-48px-R.png) left no-repeat;
            background-size: cover;
            content: " ";
            height: rem-calc(46);
            width: rem-calc(46);
            display: inline-block;
            margin-right: rem-calc(16);
            vertical-align: middle;
        }

        @include hocus {
            color: map-get($foundation-palette, primary);
            border: rem-calc(2) solid map-get($foundation-palette, primary);
            background: $white;
        }
    }
}
