.article {
    position: relative;
    box-shadow: 0 3px 2px 0 rgba(36,36,36,.30);
    border-radius: $theme-border-radius;
    background-color: $white;
    border-bottom-left-radius: $theme-border-radius;
    border-bottom-right-radius: $theme-border-radius;
    width: 100%;

    > a {
        overflow: hidden;
        display: block;
    }

    &__notification {
        position: absolute;
        top: 0;
        right: 0;
        background: $theme-blue__dark;
        color: $white;
        z-index: 2;
        font-size: rem-calc(12);
        padding: rem-calc(5) rem-calc(10);
    }

    &.article--recommended {
        border: 3px solid $theme-blue__light;
        box-shadow: none;
        position: relative;
    }

    .article__banner {
        @include banner-overlay;
        position: relative;
        background-size: cover !important;
        min-height: rem-calc(176);
    }

    .article__banner--1 {
        background: url(banner-image(1, "small")) no-repeat center center;
    }

    .article__banner--2 {
        background: url(banner-image(2, "small")) no-repeat center center;
    }

    .article__banner--3 {
        background: url(banner-image(3, "small")) no-repeat center center;
    }

    .article__banner--4 {
        background: url(banner-image(4, "small")) no-repeat center center;
    }

    .article__banner--5 {
        background: url(banner-image(5, "small")) no-repeat center center;
    }

    .article__banner--6 {
        background: url(banner-image(6, "small")) no-repeat center center;
    }

    .article__banner--7 {
        background: url(banner-image(7, "small")) no-repeat center center;
    }

    .article__banner--8 {
        background: url(banner-image(8, "small")) no-repeat center center;
    }

    .article__banner--9 {
        background: url(banner-image(9, "small")) no-repeat center center;
    }

    .article__banner--10 {
        background: url(banner-image(10, "small")) no-repeat center center;
    }

    .article__banner--11 {
        background: url(banner-image(11, "small")) no-repeat center center;
    }

    .article__banner--12 {
        background: url(banner-image(12, "small")) no-repeat center center;
    }

    .article__banner--13 {
        background: url(banner-image(13, "small")) no-repeat center center;
    }

    .article__banner--14 {
        background: url(banner-image(14, "small")) no-repeat center center;
    }

    .article__banner--15 {
        background: url(banner-image(15, "small")) no-repeat center center;
    }

    .article__banner--16 {
        background: url(banner-image(16, "small")) no-repeat center center;
    }

    .article__banner--17 {
        background: url(banner-image(17, "small")) no-repeat center center;
    }

    .article__logo {
        position: relative;
        top: -48px;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .article__content {
        position: relative;
        top: -18px;

        h2 {
            font-size: rem-calc(28);
        }

        .article__sub-heading {
            font-size: rem-calc(14);
        }
    }

    .article__meta {
        border-top: 1px solid $theme-grey;
    }
}
