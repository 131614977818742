body {
    background-color: $theme-grey;
    &.companies-show,
    &.project-summary,
    &.mandate-summary {
        .notification {
            padding-bottom: $global-margin;
        }
    }
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
    float: none !important;
}

input,
a,
button,
div,
span {
    outline: 0;
}

.pagination {
    text-align: center;
    display: inline-block;
    margin: 0;
    width: 100%;
    padding-bottom: 1rem;
    li {
        list-style: none;
        display: inline-block;
        padding: 10px 15px;
        background: $theme-blue;
        margin: 5px;
        line-height: 1;
        border-radius: $theme-border-radius;
        transition: $theme-transition-default;
        @include hocus {
            background-color: darken($theme-blue, 10%);
            cursor: pointer;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            font-weight: bold;
            background-color: darken($theme-blue, 10%);
        }
        span,
        a {
            color: $white;
        }
    }
}

ul.height-equal {
    display: flex;
    flex-wrap: wrap;
    li {
        display: flex;
    }
}

.text-overflow-break {
    word-wrap: break-word;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.tip-plain {
    border: 0;
    font-weight: normal;
}

.required {
    color: $theme-red;
    font-size: 1rem;
}

.color--red {
    color: $theme-red;
}

.break-word {
    word-break: break-word;
}

.orbitt-recommended-text {
    z-index: $theme-z-index-large;
    position: absolute;
    right: 60px;
    top: 5px;
}

.orbit-recommended {
    position: absolute;
    z-index: $theme-z-index-large;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: rem-calc(60) solid $theme-blue__light;
    border-left: rem-calc(60) solid transparent;
    svg {
        fill: $white;
        position: absolute;
        top: -53px;
        right: 3px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.form-group-no-margin {
    .form-group {
        margin: 0;
    }
}

.column-block-small-padding {
    padding: 0 rem-calc(7.5);
    .column-block-small-padding__item {
        padding: 0 rem-calc(7.5);
    }
}

.banner {
    .summary_banner {
        @include banner-overlay;
        position: relative;
        background-size: cover !important;
        min-height: rem-calc(176);
    }
    .summary__banner--1 {
        background: url(banner-image(1, "large")) no-repeat center center;
    }
    .summary__banner--2 {
        background: url(banner-image(2, "large")) no-repeat center center;
    }
    .summary__banner--3 {
        background: url(banner-image(3, "large")) no-repeat center center;
    }
    .summary__banner--4 {
        background: url(banner-image(4, "large")) no-repeat center center;
    }
    .summary__banner--5 {
        background: url(banner-image(5, "large")) no-repeat center center;
    }
    .summary__banner--6 {
        background: url(banner-image(6, "large")) no-repeat center center;
    }
    .summary__banner--7 {
        background: url(banner-image(7, "large")) no-repeat center center;
    }
    .summary__banner--8 {
        background: url(banner-image(8, "large")) no-repeat center center;
    }
    .summary__banner--9 {
        background: url(banner-image(9, "large")) no-repeat center center;
    }
    .summary__banner--10 {
        background: url(banner-image(10, "large")) no-repeat center center;
    }
    .summary__banner--11 {
        background: url(banner-image(11, "large")) no-repeat center center;
    }
    .summary__banner--12 {
        background: url(banner-image(12, "large")) no-repeat center center;
    }
    .summary__banner--13 {
        background: url(banner-image(13, "large")) no-repeat center center;
    }
    .summary__banner--14 {
        background: url(banner-image(14, "large")) no-repeat center center;
    }
    .summary__banner--15 {
        background: url(banner-image(15, "large")) no-repeat center center;
    }
    .summary__banner--16 {
        background: url(banner-image(16, "large")) no-repeat center center;
    }
    .summary__banner--17 {
        background: url(banner-image(17, "large")) no-repeat center center;
    }
}

.line-through {
    p,
    a {
        text-decoration: line-through !important;
    }
}

.bold {
    font-weight: bold;
}

.dim-down {
    opacity: .5;
}

.position-relative {
    position: relative;
}

.link-white {
    color: $white;
    text-decoration: underline;
    transition: $theme-transition-default;
    @include hocus {
        color: $theme-blue;
    }
}

.link-blue {
    color: $theme-blue;
    text-decoration: underline;
    transition: $theme-transition-default;
    &.link-blue--uppercase {
        text-transform: uppercase;
    }
    &.link-blue--plain {
        text-decoration: none;
    }
    // @include hocus {
    //     color: $theme-blue__light;
    // }
}

.link-black {
    color: $theme-black;
    text-decoration: underline;
    transition: $theme-transition-default;
    // @include hocus {
    //     color: $theme-blue;
    // }
}

.dark-border-bottom {
    border-bottom: 1px solid $theme-dark-border;
}

.pretty-table {
    .pretty-table__bool {
        svg {
            fill: $theme-blue;
        }
    }
    .even {
        background-color: $theme-grey;
    }
}

.curved-image {
    position: relative;
    clip-path: $theme-right-angle;
    left: -1px;
    width: calc(100% + 1px);
}

.banner-negative-margin {
    margin-top: rem-calc(-100);
    position: relative;
    z-index: $theme-z-index-medium;
}

.image-with-border {
    border: 1px solid $white;
}

.svg--blue {
    svg {
        fill: $theme-blue;
    }
}

.pretty-text {
    @include text-style();
}

.dotted-border {
    border-bottom: 1px dotted $theme-font-white;
}

a.label-text {
    &.label-text--blue,
    &.label-text--white {
        @include hocus {
            color: $theme-blue__dark !important;
        }
    }
}

.label-text {
    @include label-styles();
    &.label-text--white {
        color: $white;
    }
    &.label-text--blue {
        color: $theme-blue;
    }
    &.label-text--no-case {
        text-transform: none;
    }
}

.visibility-opaque {
    opacity: 0.8;
}

.white-block {
    background-color: $white;
    border-radius: $theme-border-radius;
    padding: $global-margin;
    margin-bottom: $global-margin;
    box-shadow: $theme-white-box-shadow;
    width: 100%;
    p {
        @include text-style();
    }
}

.no-case {
    text-transform: none;
}

.sub-heading {
    font-size: rem-calc(18) !important;
    border-bottom: 1px solid $theme-font-white;
    padding-bottom: rem-calc(10);
}

.no-border {
    border: 0 !important;
}

.small-heading {
    font-size: rem-calc(14);
    color: $theme-font-white;
}

.color-grey {
    color: $theme-font-white;
}

.no-background-color {
    background: none;
    background-color: transparent;
}

.dotted-border-bottom {
    border-bottom: 1px dotted $theme-font-white;
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
    float: left;
}

.tiny-text {
    font-size: rem-calc(12);
}

.cursor-pointer {
    cursor: pointer;
}

.font-size-medium {
    font-size: rem-calc($font-size-medium);
}

.text-uppercase {
    text-transform: uppercase;
}

.pad-top {
    padding-top: $global-margin;
}

.pad-top-large {
    padding-top: $global-margin * 2;
}

.pad-bottom-large {
    padding-bottom: $global-margin * 2;
}

.pad-bottom {
    padding-bottom: $global-margin;
}

.pad-bottom-small {
    padding-bottom: 0.5rem;
}

.pad-top-small {
    padding-top: 0.5rem;
}

.margin-right-small {
    margin-right: 0.5rem;
}

.pad-top-large {
    padding-top: rem-calc(30);
}

.margin-top {
    margin-top: $global-margin;
}

.margin-bottom {
    margin-bottom: $global-margin;
}

.margin-bottom {
    margin-bottom: $global-margin;
}

.no-padding {
    padding: 0;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.margin-auto {
    margin: 0 auto;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.full-height {
    min-height: 100vh;
}

.full-height__max {
    max-height: 100%;
}

.bg-white {
    background-color: $white;
}

.bg-black {
    background-color: $theme-black;
}

.bg-grey {
    background-color: $theme-grey;
}

.bg-grey__dark {
    background-color: $theme-grey__dark;
}

.bg-blue__dark {
    background-color: $theme-blue__dark;
}

.color-black {
    color: $theme-black !important;
}

.color-blue {
    color: $theme-blue;
}

.color-dark-grey {
    color: $theme-grey__dark;
}

.color-white {
    color: $white !important;
}

.color-blue-overlay-light {
    color: $theme-blue__overlay_light;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block !important;
}

.main-content-container {
    padding: rem-calc(16);
}

.main-content {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
}

.display-inline {
    display: inline !important;
}

.text-underlined {
    text-decoration: underline;
}

.no-border-radius {
    border-radius: 0;
}

.parent-heading {
    font-weight: normal;
    text-transform: uppercase;
    font-size: rem-calc(16);
    color: #5C6163;
    border-bottom: 1px solid $theme-font-white;
    span {
        font-weight: normal;
    }
}

.parent-heading.removelne {
    border-bottom: none;
}

// Heading sizes
@each $size,
$headers in $header-styles {
    @include breakpoint($size) {
        @each $heading,
        $styles in $headers {
            @each $name,
            $rule in $styles {
                @if $name=='font-size' {
                    #{"." + $heading} {
                        font-size: rem-calc($rule);
                        line-height: $header-lineheight;
                    }
                }
            }
        }
    }
}

@media #{breakpoint(medium)} {
    .medium-text-right {
        text-align: right;
    }
    .medium-text-center {
        text-align: center;
    }
    .medium-text-left {
        text-align: left;
    }
    .banner-negative-margin {
        margin-top: rem-calc(-60);
    }
}

@media #{breakpoint(large)} {
    .scrollable {
        overflow: hidden;
        max-height: 100vh;
        overflow: scroll;
    }
    .main-content {
        -webkit-box-flex: 1;
        flex: 1 1 0px;
    }
}