.financial-information {
    .financial-information__body {
        p {
            margin-bottom: 0;
            color: $theme-grey__dark;
        }
    }

    .financial-information__align {
        margin: rem-calc(15) 0;
        position: relative;
    }

    .financial-information__checkbox {
        .form-group {
            padding-top: rem-calc(8);
            padding-bottom: rem-calc(8);
        }
    }
}
