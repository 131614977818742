ul.tabs {
    .tabs-title {
        @include hocus {
            > a[aria-selected='false'] {
                color: $theme-blue;
                background: $white;
            }
        }

        > a {
            transition: $theme-transition-default;
            text-transform: uppercase;
            border-top-left-radius: $theme-border-radius;
            border-top-right-radius: $theme-border-radius;
        }

        > a[aria-selected='false'] {
            background: $theme-font-white;
        }
    }

    + .tabs-content {
        border-bottom-left-radius: $theme-border-radius;
        border-bottom-right-radius: $theme-border-radius;
        box-shadow: $theme-white-box-shadow;
    }
}
