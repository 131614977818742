.main-menu {
    width: 100%;
    height: rem-calc(57);

    $parent: &;

    &__button {
        color: $white;
        padding: rem-calc(16);
        cursor: pointer;

        p {
            color: $white;
            font-weight: bold;
            margin: 0;
            letter-spacing: rem-calc(2);

            &::after {
                content: "+";
                float: right;
            }
        }

        &.toggled {
            p {
                &::after {
                    content: "-";
                }
            }
        }
    }

    &__sub-menu-label {
        fill: $white;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

    }

    &__sub-menu {
        margin-top: rem-calc(20);
    }

    &__sub-menu-toggle {
        cursor: pointer;

        svg {
            padding: rem-calc(4);
            box-sizing: content-box;
            border-radius: 50%;
            position: relative;
            right: rem-calc(-4);
        }
        
        &__minus {
            display: none;

            svg {
                background: $theme-blue;
            }            
        }

        &__plus {
            svg {
                background: $theme-black;
            }
        }        
    }

    &__navigation {
        > ul {
            margin-bottom: 0;

            > li {
                border-bottom: 1px solid rgba($theme-black, .3);
                padding: 0.9375rem; 
            }
        }

        li {
            @include hocus-active {
                > a {
                    font-weight: bold;
                    background-color: $theme-grey;
                    color: $theme-blue__light;
                    border-top-left-radius: $theme-border-radius;
                    border-bottom-left-radius: $theme-border-radius;
                    margin-right: -1rem;
                    padding-left: $global-margin;

                    .has-tip {
                        font-weight: bold;
                    }                    
                }
            }
        }

        a {
            color: $theme-grey;
            text-decoration: none;
            font-size: rem-calc(14);
            padding-top: rem-calc(5);
            padding-bottom: rem-calc(5);
            margin: rem-calc(5) 0;
            transition: $theme-transition-default;
            letter-spacing: 1.17px;
            display: block;
        }

        &--no-sub-menu {
            > ul > li {
                padding: 0 0.9375rem; 
            }
        }        
    }

    &--two-items {
        li {
            @include hocus-active {
                span {
                    color: $theme-black !important;
                }

                small {
                    color: $theme-grey__dark !important;
                }
            }
        }

        a {
            font-size: rem-calc(16);
        }
    }

    .menu-container {
        //padding: rem-calc(16);
        display: none;

        &.toggled {
            display: block;
        }
    }

    &.toggled {
        height: auto;
    }
}

@media #{breakpoint(large)} {
    .main-menu {
        height: auto;
        width: rem-calc(213);

        &__button {
            display: none;
        }
        .menu-container {
            display: block;
        }
    }
}
