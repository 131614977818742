.filters {
    .filters__list {
        a {
            position: relative;
            color: $white;
            transition: $theme-transition-default;

            @include before {
                position: absolute;
                left: 0;
                border-radius: $theme-border-radius / 2;
                margin-right: rem-calc(10);
                background: $theme-grey;
                height: rem-calc(16);
                width: rem-calc(16);
                margin-top: rem-calc(4.5);
                margin-bottom: rem-calc(4.5);
                display: block;
                float: left;
            }

            @include hocus {
                color: $theme-blue;
            }

            &.active {
                @include before {
                    background: $theme-blue url('../../svg/form-tick-white.svg') center center no-repeat;
                    background-size: 7px 5px;
                }
            }

            > span {
                margin-top: rem-calc(4.5);
                position: relative;
                left: rem-calc(30);
                width: calc(100% - 30px);
                word-wrap: break-word;
                display: block;
            }
        }
    }
}
