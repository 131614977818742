@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Poppins");
/**
 * Foundation for Sites by ZURB
 * Version 6.2.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=47.5em&large=80em&xlarge=90em&xxlarge=115em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #00162A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 4px;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 4px;
  background: transparent;
  line-height: 1;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: auto;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 47.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print, screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
}

@media print, screen and (min-width: 47.5em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 47.5em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 47.5em) and (min-width: 47.5em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 47.5em) {
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 47.5em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 47.5em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 80em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 80em) and (min-width: 80em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 80em) {
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 80em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 80em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 47.5em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 47.5em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 80em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 47.5em) {
  h1 {
    font-size: 2.625rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.9375rem;
  }
  h4 {
    font-size: 1.5625rem;
  }
  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #1FAAE2;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: #185B76;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: auto;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr {
  border-bottom: 1px dotted #000000;
  color: #00162A;
  cursor: help;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000000;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000;
  border-radius: 4px;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 47.5em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 80em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

.accordion {
  margin-left: 0;
  background: #FFFFFF;
  list-style-type: none;
}

.accordion-item:first-child > :first-child {
  border-radius: 4px 4px 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 4px 4px;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 4px 4px;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #FFFFFF;
  color: #00162A;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.callout {
  position: relative;
  margin: 0 0 0 0;
  padding: 1rem;
  border: 1px solid #CED8DB;
  border-radius: 4px;
  background-color: white;
  color: #00162A;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #d7ecfa;
  color: #00162A;
}

.callout.secondary {
  background-color: #eaeaea;
  color: #00162A;
}

.callout.success {
  background-color: #ecfadc;
  color: #00162A;
}

.callout.warning {
  background-color: #fffddf;
  color: #00162A;
}

.callout.alert {
  background-color: #fbd9df;
  color: #00162A;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 47.5em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns,
.reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 47.5em) {
  .reveal {
    width: 600px;
    max-width: auto;
  }
}

@media print, screen and (min-width: 47.5em) {
  .reveal .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 47.5em) {
  .reveal.tiny {
    width: 30%;
    max-width: auto;
  }
}

@media print, screen and (min-width: 47.5em) {
  .reveal.small {
    width: 50%;
    max-width: auto;
  }
}

@media print, screen and (min-width: 47.5em) {
  .reveal.large {
    width: 90%;
    max-width: auto;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 47.4375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px;
}

thead,
tbody,
tfoot {
  border: 1px solid #f2f2f2;
  background-color: #FFFFFF;
}

caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
}

thead {
  background: #f9f9f9;
  color: #00162A;
}

tfoot {
  background: #f2f2f2;
  color: #00162A;
}

thead tr,
tfoot tr {
  background: transparent;
}

thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f2f2f2;
}

table.unstriped tbody {
  background-color: #FFFFFF;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: #FFFFFF;
}

@media screen and (max-width: 79.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f4f4f4;
}

table.hover tfoot tr:hover {
  background-color: #ededed;
}

table.hover tbody tr:hover {
  background-color: #fafafa;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid 0;
  background: #FFFFFF;
  list-style-type: none;
}

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
}

.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}

.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #1779ba;
}

.tabs.primary > li > a {
  color: #000000;
}

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #1673b1;
}

.tabs-title {
  float: left;
}

.tabs-title > a {
  display: block;
  padding: 1.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #5C6163;
}

.tabs-title > a:hover {
  background: #FFFFFF;
  color: #4f5355;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #FFFFFF;
  color: #1FAAE2;
}

.tabs-content {
  border: 1px solid 0;
  border-top: 0;
  background: #FFFFFF;
  color: #00162A;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid 0;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel[aria-hidden="false"] {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 5px #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(25, 63, 78, 0.25);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}

a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 2px 5px 0 rgba(25, 63, 78, 0.25);
}

a.thumbnail image {
  box-shadow: none;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 4px;
  background-color: #000000;
  font-size: 80%;
  color: #FFFFFF;
}

.tooltip::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #000000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #000000 transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000000;
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000000 transparent transparent;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 47.4375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 47.5em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 47.5em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 47.4375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 47.5em) and (max-width: 79.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 47.4375em), screen and (min-width: 80em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 80em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 79.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 80em) and (max-width: 89.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 79.9375em), screen and (min-width: 90em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
}

.clearfix::after {
  clear: both;
}

.banner .summary_banner::before, .form-group input[type="radio"]:checked::after, .form-group input[type="checkbox"]:checked::after, .form-group .select-container::after, .global-menu__icons li::before, .global-menu__icons li:focus::before, .global-menu__icons li:hover::before, .global-menu__icons li.active::before, .article .article__banner::before, .filters .filters__list a::before, .filters .filters__list a.active::before {
  content: "";
  display: block;
}

.auth.auth__bg {
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-image: radial-gradient(0% 75%, #1782AD 0%, #185B76 67%);
  background-image: -webkit-radial-gradient(0% 75%, #1782AD 0%, #185B76 67%);
}

.auth .auth__width {
  max-width: 39.0625rem;
}

.auth .auth__svg {
  margin: 0 auto;
}

.auth .auth__svg svg {
  fill: #FFFFFF;
}

body {
  background-color: #E9EFF1;
}

body.companies-show .notification, body.project-summary .notification, body.mandate-summary .notification {
  padding-bottom: 1rem;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child),
.columns:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: none !important;
}

input,
a,
button,
div,
span {
  outline: 0;
}

.pagination {
  text-align: center;
  display: inline-block;
  margin: 0;
  width: 100%;
  padding-bottom: 1rem;
}

.pagination li {
  list-style: none;
  display: inline-block;
  padding: 10px 15px;
  background: #1FAAE2;
  margin: 5px;
  line-height: 1;
  border-radius: 4px;
  transition: ease 250ms;
}

.pagination li:focus, .pagination li:hover {
  background-color: #1889b6;
  cursor: pointer;
}

.pagination li:first-child {
  margin-left: 0;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination li.active {
  font-weight: bold;
  background-color: #1889b6;
}

.pagination li span,
.pagination li a {
  color: #FFFFFF;
}

ul.height-equal {
  display: flex;
  flex-wrap: wrap;
}

ul.height-equal li {
  display: flex;
}

.text-overflow-break {
  word-wrap: break-word;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.tip-plain {
  border: 0;
  font-weight: normal;
}

.required {
  color: #CC1638;
  font-size: 1rem;
}

.color--red {
  color: #CC1638;
}

.break-word {
  word-break: break-word;
}

.orbitt-recommended-text {
  z-index: 999;
  position: absolute;
  right: 60px;
  top: 5px;
}

.orbit-recommended {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 3.75rem solid #185B76;
  border-left: 3.75rem solid transparent;
}

.orbit-recommended svg {
  fill: #FFFFFF;
  position: absolute;
  top: -53px;
  right: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group-no-margin .form-group {
  margin: 0;
}

.column-block-small-padding {
  padding: 0 0.46875rem;
}

.column-block-small-padding .column-block-small-padding__item {
  padding: 0 0.46875rem;
}

.banner .summary_banner {
  position: relative;
  background-size: cover !important;
  min-height: 11rem;
}

.banner .summary_banner::before {
  background-color: rgba(5, 24, 68, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.banner .summary__banner--1 {
  background: url("/storage/banners/large/1.jpeg") no-repeat center center;
}

.banner .summary__banner--2 {
  background: url("/storage/banners/large/2.jpeg") no-repeat center center;
}

.banner .summary__banner--3 {
  background: url("/storage/banners/large/3.jpeg") no-repeat center center;
}

.banner .summary__banner--4 {
  background: url("/storage/banners/large/4.jpeg") no-repeat center center;
}

.banner .summary__banner--5 {
  background: url("/storage/banners/large/5.jpeg") no-repeat center center;
}

.banner .summary__banner--6 {
  background: url("/storage/banners/large/6.jpeg") no-repeat center center;
}

.banner .summary__banner--7 {
  background: url("/storage/banners/large/7.jpeg") no-repeat center center;
}

.banner .summary__banner--8 {
  background: url("/storage/banners/large/8.jpeg") no-repeat center center;
}

.banner .summary__banner--9 {
  background: url("/storage/banners/large/9.jpeg") no-repeat center center;
}

.banner .summary__banner--10 {
  background: url("/storage/banners/large/10.jpeg") no-repeat center center;
}

.banner .summary__banner--11 {
  background: url("/storage/banners/large/11.jpeg") no-repeat center center;
}

.banner .summary__banner--12 {
  background: url("/storage/banners/large/12.jpeg") no-repeat center center;
}

.banner .summary__banner--13 {
  background: url("/storage/banners/large/13.jpeg") no-repeat center center;
}

.banner .summary__banner--14 {
  background: url("/storage/banners/large/14.jpeg") no-repeat center center;
}

.banner .summary__banner--15 {
  background: url("/storage/banners/large/15.jpeg") no-repeat center center;
}

.banner .summary__banner--16 {
  background: url("/storage/banners/large/16.jpeg") no-repeat center center;
}

.banner .summary__banner--17 {
  background: url("/storage/banners/large/17.jpeg") no-repeat center center;
}

.line-through p,
.line-through a {
  text-decoration: line-through !important;
}

.bold {
  font-weight: bold;
}

.dim-down {
  opacity: .5;
}

.position-relative {
  position: relative;
}

.link-white {
  color: #FFFFFF;
  text-decoration: underline;
  transition: ease 250ms;
}

.link-white:focus, .link-white:hover {
  color: #1FAAE2;
}

.link-blue {
  color: #1FAAE2;
  text-decoration: underline;
  transition: ease 250ms;
}

.link-blue.link-blue--uppercase {
  text-transform: uppercase;
}

.link-blue.link-blue--plain {
  text-decoration: none;
}

.link-black {
  color: #00162A;
  text-decoration: underline;
  transition: ease 250ms;
}

.dark-border-bottom {
  border-bottom: 1px solid #091F27;
}

.pretty-table .pretty-table__bool svg {
  fill: #1FAAE2;
}

.pretty-table .even {
  background-color: #E9EFF1;
}

.curved-image {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 87%, 0 100%);
  left: -1px;
  width: calc(100% + 1px);
}

.banner-negative-margin {
  margin-top: -6.25rem;
  position: relative;
  z-index: 99;
}

.image-with-border {
  border: 1px solid #FFFFFF;
}

.svg--blue svg {
  fill: #1FAAE2;
}

.pretty-text {
  font-size: 0.875rem;
  color: #5C6163;
}

.dotted-border {
  border-bottom: 1px dotted #CED8DB;
}

a.label-text.label-text--blue:focus, a.label-text.label-text--blue:hover, a.label-text.label-text--white:focus, a.label-text.label-text--white:hover {
  color: #10313E !important;
}

.label-text {
  font-size: 0.875rem;
  color: #5C6163;
  display: block;
  text-transform: none;
  letter-spacing: 1.17px;
  padding-bottom: 0.5rem;
}

.label-text.label-text--white {
  color: #FFFFFF;
}

.label-text.label-text--blue {
  color: #1FAAE2;
}

.label-text.label-text--no-case {
  text-transform: none;
}

.visibility-opaque {
  opacity: 0.8;
}

.white-block {
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 3px 7px 3px -5px rgba(25, 63, 78, 0.25);
  width: 100%;
}

.white-block p {
  font-size: 0.875rem;
  color: #5C6163;
}

.no-case {
  text-transform: none;
}

.sub-heading {
  font-size: 1.125rem !important;
  border-bottom: 1px solid #CED8DB;
  padding-bottom: 0.625rem;
}

.no-border {
  border: 0 !important;
}

.small-heading {
  font-size: 0.875rem;
  color: #CED8DB;
}

.color-grey {
  color: #CED8DB;
}

.no-background-color {
  background: none;
  background-color: transparent;
}

.dotted-border-bottom {
  border-bottom: 1px dotted #CED8DB;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: left;
}

.tiny-text {
  font-size: 0.75rem;
}

.cursor-pointer {
  cursor: pointer;
}

.font-size-medium {
  font-size: 1.5rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.pad-top {
  padding-top: 1rem;
}

.pad-top-large {
  padding-top: 2rem;
}

.pad-bottom-large {
  padding-bottom: 2rem;
}

.pad-bottom {
  padding-bottom: 1rem;
}

.pad-bottom-small {
  padding-bottom: 0.5rem;
}

.pad-top-small {
  padding-top: 0.5rem;
}

.margin-right-small {
  margin-right: 0.5rem;
}

.pad-top-large {
  padding-top: 1.875rem;
}

.margin-top {
  margin-top: 1rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.margin-auto {
  margin: 0 auto;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.full-height {
  min-height: 100vh;
}

.full-height__max {
  max-height: 100%;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-black {
  background-color: #00162A;
}

.bg-grey {
  background-color: #E9EFF1;
}

.bg-grey__dark {
  background-color: #5C6163;
}

.bg-blue__dark {
  background-color: #10313E;
}

.color-black {
  color: #00162A !important;
}

.color-blue {
  color: #1FAAE2;
}

.color-dark-grey {
  color: #5C6163;
}

.color-white {
  color: #FFFFFF !important;
}

.color-blue-overlay-light {
  color: #213E6F;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block !important;
}

.main-content-container {
  padding: 1rem;
}

.main-content {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
}

.display-inline {
  display: inline !important;
}

.text-underlined {
  text-decoration: underline;
}

.no-border-radius {
  border-radius: 0;
}

.parent-heading {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1rem;
  color: #5C6163;
  border-bottom: 1px solid #CED8DB;
}

.parent-heading span {
  font-weight: normal;
}

.parent-heading.removelne {
  border-bottom: none;
}

.h1 {
  font-size: 1.5rem;
  line-height: 1.4;
}

.h2 {
  font-size: 1.25rem;
  line-height: 1.4;
}

.h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
}

.h4 {
  font-size: 1.125rem;
  line-height: 1.4;
}

.h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
}

.h6 {
  font-size: 1rem;
  line-height: 1.4;
}

@media print, screen and (min-width: 47.5em) {
  .h1 {
    font-size: 2.625rem;
    line-height: 1.4;
  }
  .h2 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .h3 {
    font-size: 1.9375rem;
    line-height: 1.4;
  }
  .h4 {
    font-size: 1.5625rem;
    line-height: 1.4;
  }
  .h5 {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  .h6 {
    font-size: 1rem;
    line-height: 1.4;
  }
}

@media (min-width: 47.5em) {
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-left {
    text-align: left;
  }
  .banner-negative-margin {
    margin-top: -3.75rem;
  }
}

@media (min-width: 80em) {
  .scrollable {
    overflow: hidden;
    max-height: 100vh;
    overflow: scroll;
  }
  .main-content {
    -webkit-box-flex: 1;
    flex: 1 1 0px;
  }
}

.chat .chat__messages {
  border: 1px solid #CED8DB;
  padding: 0 0.90625rem;
  height: 12.5rem;
  overflow-y: scroll;
  border-radius: 4px;
}

.chat .chat__messages li {
  padding-top: 0.90625rem;
  padding-bottom: 0.90625rem;
}

.chat .chat__messages li p {
  border-radius: 4px;
  padding: 0.625rem;
  display: inline-block;
  max-width: 50%;
  word-wrap: break-word;
}

.chat .chat__messages li p.mine {
  background-color: #1FAAE2;
  color: #FFFFFF;
  float: right;
}

.chat .chat__messages li p.theirs {
  background-color: #E9EFF1;
  color: #00162A;
  float: left;
}

.chat .chat__messages li a {
  color: inherit;
  text-decoration: underline;
}

.chat .chat__messages--large {
  height: 18.75rem;
}

.chat .chat__form textarea {
  min-height: 4.6875rem;
}

.button {
  text-transform: none;
  border-radius: 0.25rem;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: ease 250ms;
}

.button:focus {
  outline: 0;
}

.button--shrink {
  width: auto !important;
  display: inline-block;
}

.button > span {
  display: inherit;
  padding: 1rem;
  letter-spacing: 2.44px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.button svg {
  margin: 1rem;
}

.button .button--letter-spacing-small {
  letter-spacing: 1.17px !important;
}

.button--large {
  font-size: 1rem;
  font-weight: bold;
}

.button--clear {
  background-color: transparent;
  border: 1px solid #CED8DB;
  color: #1FAAE2;
}

.button--clear:disabled {
  background-color: #b1c1c5;
}

.button--clear:focus, .button--clear:hover {
  background-color: #1FAAE2;
  border: 1px solid #1FAAE2;
  color: #FFFFFF;
}

.button--clear:focus:disabled, .button--clear:hover:disabled {
  background-color: #1889b6;
}

.button--clear:focus svg, .button--clear:hover svg {
  fill: #FFFFFF;
}

.button--blue {
  background-color: #1FAAE2;
  border: 1px solid #1FAAE2;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.button--blue:disabled {
  background-color: #1889b6;
}

.button--blue svg {
  fill: #FFFFFF;
}

.button--blue:focus, .button--blue:hover {
  background-color: #1889b6;
  border: 1px solid #1889b6;
  color: #FFFFFF;
}

.button--blue:focus:disabled, .button--blue:hover:disabled {
  background-color: #126789;
}

.button--blue:focus svg, .button--blue:hover svg {
  fill: #FFFFFF;
}

.button--dark-blue {
  background-color: transparent;
  border: 1px solid #CED8DB;
  color: #185B76;
}

.button--dark-blue:disabled {
  background-color: #0f3a4c;
}

.button--dark-blue svg {
  fill: #185B76;
}

.button--dark-blue:focus, .button--dark-blue:hover {
  background-color: #1FAAE2;
  border: 1px solid #1FAAE2;
  color: #FFFFFF;
}

.button--dark-blue:focus:disabled, .button--dark-blue:hover:disabled {
  background-color: #1889b6;
}

.button--dark-blue:focus svg, .button--dark-blue:hover svg {
  fill: #FFFFFF;
}

.button.button-dark-blue-inverted {
  background-color: #185B76;
  border: 1px solid #185B76;
  color: #FFFFFF;
}

.button.button-dark-blue-inverted:disabled {
  background-color: #0f3a4c;
}

.button.button-dark-blue-inverted svg {
  fill: #FFFFFF;
}

.button.button-dark-blue-inverted:focus, .button.button-dark-blue-inverted:hover {
  background-color: transparent;
  border: 1px solid #CED8DB;
  color: #185B76;
}

.button.button-dark-blue-inverted:focus:disabled, .button.button-dark-blue-inverted:hover:disabled {
  background-color: #0f3a4c;
}

.button.button-dark-blue-inverted:focus svg, .button.button-dark-blue-inverted:hover svg {
  fill: #185B76;
}

.button--red {
  background-color: #CC1638;
  border: 1px solid #CC1638;
  color: #FFFFFF;
}

.button--red:disabled {
  background-color: #9e112b;
}

.button--red svg {
  fill: #FFFFFF;
}

.button--red:focus, .button--red:hover {
  background-color: transparent;
  border: 1px solid #CED8DB;
  color: #1FAAE2;
}

.button--red:focus:disabled, .button--red:hover:disabled {
  background-color: #b1c1c5;
}

.button--green {
  background-color: #7ED321;
  border: 1px solid #7ED321;
  color: #FFFFFF;
}

.button--green:disabled {
  background-color: #64a71a;
}

.button--green svg {
  fill: #FFFFFF;
}

.button--green:focus, .button--green:hover {
  background-color: transparent;
  border: 1px solid #CED8DB;
  color: #1FAAE2;
}

.button--green:focus:disabled, .button--green:hover:disabled {
  background-color: #b1c1c5;
}

.button--grey {
  border: 1px solid #CED8DB;
  color: #CED8DB;
}

.button--grey:disabled {
  background-color: #b1c1c5;
}

.button--grey svg {
  fill: #CED8DB;
}

.button--grey:focus, .button--grey:hover {
  background-color: #1FAAE2;
  border: 1px solid #1FAAE2;
  color: #FFFFFF;
}

.button--grey:focus:disabled, .button--grey:hover:disabled {
  background-color: #1889b6;
}

.button--grey:focus svg, .button--grey:hover svg {
  fill: #FFFFFF;
}

.button--dotted-blue {
  border: 1px dotted #1FAAE2;
  color: #1FAAE2;
}

.button--dotted-blue:disabled {
  background-color: #1889b6;
}

.button--dotted-blue svg {
  fill: #1FAAE2;
}

.button--svg {
  text-align: left;
}

.button--svg span {
  display: inline-block;
}

.button--svg svg {
  float: right;
}

.button--svg-blue svg {
  fill: #1FAAE2;
}

.button--svg-dark-blue svg {
  fill: #185B76;
}

.button--svg-edit, .button--svg-trash {
  width: 100%;
}

.button--svg-edit svg, .button--svg-trash svg {
  margin-top: 0.6875rem;
  margin-bottom: 0.6875rem;
}

.button--svg-watch svg {
  margin-top: 0.90625rem;
  margin-bottom: 0.90625rem;
}

.button--svg-plus svg {
  margin-top: 0.84375rem;
  margin-bottom: 0.84375rem;
}

.button.button--svg-envelope svg {
  margin: 0.65625rem 0.5rem;
}

.button--small > span {
  padding-top: 0.53125rem;
  padding-bottom: 0.53125rem;
}

.button--small svg {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.button--text-center {
  text-align: center;
}

.button--text-center svg {
  float: none;
  margin: 0;
}

.button--mob-full {
  width: 100%;
  margin-top: 1rem;
}

.button--full-width {
  width: 100%;
}

.button--full-width > span {
  text-align: center;
  display: block;
  width: 100%;
}

.button--grey.button--svg.button--small {
  float: right;
}

.button--full-width {
  width: 100%;
}

.unpublish-form .button--blue {
  width: 100%;
}

@media print, screen and (min-width: 47.5em) {
  .mandate-button-row {
    padding-top: 1rem;
  }
  .button--mob-full {
    margin-top: 0;
  }
}

@media screen and (max-width: 79.9375em) {
  .button--full-width-small {
    width: 100% !important;
  }
}

.complete-reg-form .button {
  text-transform: none;
}

.complete-reg-form .button span {
  letter-spacing: 0;
  font-size: 1rem;
}

.complete-reg-form svg {
  margin: 0;
}

.login-form .button span {
  letter-spacing: 0;
  text-transform: none;
  font-size: 1rem;
}

.complete-reg-form .button span span {
  letter-spacing: 0;
  font-size: 0.9rem;
  display: block;
  float: left;
  line-height: 18px;
  margin: 0;
  padding: 0px 0px 10px 5px;
}

.complete-reg-form .button span.arrowsvg {
  line-height: 18px;
  margin-top: 2px;
  margin-right: 10px;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 12px;
}

.checkbox label:disabled {
  cursor: none;
}

.checkbox label:hover {
  cursor: pointer;
}

.checkbox label::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid;
  left: 0px;
  top: -11px;
}

.checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
  height: 5px;
  width: 17px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 3px;
  top: -7px;
}

.checkbox input[type="checkbox"] + label::after {
  content: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: #3b99fc auto 5px;
}

.checkbox.new {
  width: 25px;
  height: 25px;
  display: inline-flex;
  margin-right: 0.5em;
}

.checkbox.new input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
}

.checkbox.new label {
  position: relative;
  padding-left: 0;
}

.checkbox.new label::before {
  height: 22px;
  width: 22px;
  border: 1px solid #ddd;
  border-radius: 50%;
  top: -14px;
  left: 1px;
  background: #ddd;
  position: static;
}

.checkbox.new label:after {
  height: 7px;
  width: 12px;
  border-left: 3px solid #7ED321;
  border-bottom: 3px solid #7ED321;
  left: 0;
  top: -3px;
  bottom: 0;
  right: 0;
  margin: auto;
}

.checkbox.new input[type="checkbox"]:checked + label::before {
  background: #fff;
}

.checkbox.new input[type="checkbox"]:focus + label::before {
  outline: none;
}

.arrowsvg .icon__svg {
  margin: 0rem !important;
}

.notifyall-btn {
  margin-top: 29px;
}

.smallgap-2 {
  flex: 0 0 16.6666666667%;
  max-width: 11.667%;
}

.parent-heading {
  border-bottom: none;
}

.fclr {
  color: #ababab;
}

.btn-spce {
  margin-left: 25px;
}

.pretty-lists.status-lists li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.pretty-lists.status-lists li > span {
  display: flex !important;
  align-items: center;
}

.pretty-lists.status-lists li form {
  display: flex !important;
}

.tick-wrapper {
  background: #7ED321;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.5em;
}

.tick-wrapper svg {
  fill: white !important;
  display: none !important;
}

.tick-wrapper:after {
  content: "";
  height: 7px;
  width: 12px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
  left: 0;
  top: -3px;
  bottom: 0;
  right: 0;
  margin: auto;
}

.tick-wrapper.dimmed {
  background: #eee;
}

.tick-wrapper.dimmed:after {
  display: none;
}

.sub-list {
  border: 1px solid #eee;
  margin: 0;
  padding: 1em 1em 0 1em;
  display: inline-flex;
}

.sub-list li {
  margin-right: 3em;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  margin-bottom: 1em !important;
}

.sub-list li:last-child {
  margin-right: 0em;
}

.sub-list li form {
  display: flex !important;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group.has-error input[type="text"],
.form-group.has-error input[type="number"],
.form-group.has-error input[type="date"],
.form-group.has-error input[type="email"],
.form-group.has-error input[type="password"],
.form-group.has-error textarea,
.form-group.has-error select,
.form-group.has-error .select {
  border: 1px solid #CC1638;
}

.form-group .form-group__error {
  padding-top: 0;
}

.form-group .form-group__error span {
  font-size: 13px;
  background-color: transparent;
  color: red;
  padding: 0px;
}

.form-group .form-group__error span strong {
  font-weight: normal;
}

.form-group .form-group__prefix span {
  background-color: #CED8DB;
  text-align: center;
  display: block;
  font-size: 1rem;
  padding: 0.90625rem 0;
  line-height: 1.3rem;
  outline: 0;
  height: 3.0625rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-group .form-group__prefix + .columns input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-group .form-group__search input[type="search"] {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 3.0625rem;
}

.form-group .form-group__search .form-group__search-button {
  padding: 0.59375rem;
  background-color: #FFFFFF;
  border: 1px solid #CED8DB;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 3.0625rem;
}

.form-group .form-group__search .form-group__search-button svg {
  fill: #1FAAE2;
}

.form-group .form-group__file-preview {
  margin-top: -9.5px;
}

.form-group .form-group__file-name p {
  margin: 0.3125rem 0;
  text-transform: none;
  font-size: 0.875rem;
  color: #1FAAE2;
  letter-spacing: 1.17px;
}

.form-group .radio label {
  padding-top: .4rem;
}

.form-group.has-erros input[type="text"],
.form-group.has-erros input[type="number"],
.form-group.has-erros input[type="date"],
.form-group.has-erros textarea {
  border: 1px solid red;
}

.form-group label {
  font-size: 0.875rem;
  color: #5C6163;
  display: block;
  text-transform: none;
  letter-spacing: 1.17px;
  padding-bottom: 0.5rem;
}

.form-group label span {
  font-weight: normal;
  text-decoration: none;
}

.form-group input {
  height: 3.0625rem;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="date"],
.form-group input[type="checkbox"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="radio"],
.form-group input[type="search"],
.form-group textarea,
.form-group select,
.form-group .select {
  font-size: 0.875rem;
  color: #5C6163;
  font-size: 1rem;
  display: block;
  background-color: #FFFFFF;
  padding: 0.90625rem;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #CED8DB;
  line-height: 1rem;
  width: 100%;
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.form-group input[type="date"]:focus,
.form-group input[type="checkbox"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus,
.form-group input[type="radio"]:focus,
.form-group input[type="search"]:focus,
.form-group textarea:focus,
.form-group select:focus,
.form-group .select:focus {
  outline: 0;
}

.form-group input[type="text"]:disabled,
.form-group input[type="number"]:disabled,
.form-group input[type="date"]:disabled,
.form-group input[type="checkbox"]:disabled,
.form-group input[type="email"]:disabled,
.form-group input[type="password"]:disabled,
.form-group input[type="radio"]:disabled,
.form-group input[type="search"]:disabled,
.form-group textarea:disabled,
.form-group select:disabled,
.form-group .select:disabled {
  background: #CED8DB;
}

.form-group input[type="search"] {
  appearance: none;
}

.form-group select::-ms-expand {
  display: none;
}

.form-group select,
.form-group input[type="checkbox"],
.form-group input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
}

.form-group input[type="radio"] {
  position: relative;
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 50%;
}

.form-group input[type="radio"]:checked::after {
  position: absolute;
  width: 16px;
  height: 16px;
  width: 50%;
  height: 50%;
  background: #1FAAE2;
  border-radius: 50%;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.form-group input[type="checkbox"] {
  position: relative;
  height: 32px;
  width: 32px;
  padding: 0;
}

.form-group input[type="checkbox"]:checked {
  border: 1px solid #1FAAE2;
}

.form-group input[type="checkbox"]:checked::after {
  background: #1FAAE2 url("../../svg/form-tick-white.svg") center center no-repeat;
  background-size: 17px 15px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.form-group select,
.form-group .select {
  padding-right: 2.5rem;
}

.form-group .select-container {
  position: relative;
}

.form-group .select-container::after {
  pointer-events: none;
  position: absolute;
  border-left: 1px solid #CED8DB;
  display: block;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 100%;
  background: url("../../svg/form-arrow-blue.svg") center center no-repeat;
  background-size: 14px 9px;
}

.form-group textarea {
  min-height: 150px;
  max-height: 300px;
}

.form-group .input-div {
  font-size: 0.875rem;
  color: #5C6163;
  font-size: 1rem;
  display: block;
  background-color: #FFFFFF;
  padding: 0.90625rem;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #CED8DB;
  line-height: 1rem;
}

.form-group .text-transform-none {
  text-transform: none;
}

.select-search {
  position: relative;
}

.select-search input[type="search"] {
  border-radius: 0;
  border: 5px solid #CED8DB;
}

.select-search .select {
  cursor: pointer;
}

.select-search ul {
  list-style: none;
  margin: 0;
  max-height: 230px;
  overflow: scroll;
}

.select-search__options {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  z-index: 3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.select-search li {
  font-size: 0.875rem;
  color: #5C6163;
  font-size: 1rem;
  display: block;
  background-color: #FFFFFF;
  padding: 0.90625rem;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #CED8DB;
  line-height: 1rem;
  width: 100%;
  border-radius: 0;
  border-top: 0;
  cursor: pointer;
  transition: padding ease 250ms;
}

.select-search li:focus, .select-search li:hover {
  padding-left: 40px;
  text-decoration: underline;
}

.complete-reg-form .form-group label {
  text-transform: none;
}

.login-form .form-group label {
  text-transform: none;
}

.scrollable-y {
  overflow-x: hidden;
}

.scrollable-x {
  overflow-y: hidden;
}

.global-menu {
  background-image: radial-gradient(0% 45%, #1782AD 0%, #185B76 100%);
  background-image: -webkit-radial-gradient(0% 45%, #1782AD 0%, #185B76 100%);
  width: 100%;
}

.global-menu__logo {
  padding: 1.0625rem 0;
  min-width: 6.5rem;
}

.global-menu__logo svg {
  display: block;
  margin: 0 auto;
  fill: #FFFFFF;
}

.global-menu__icons {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  justify-content: flex-end;
}

.global-menu__icons li {
  position: relative;
}

.global-menu__icons li::before {
  transition: ease 250ms;
}

.global-menu__icons li:focus::before, .global-menu__icons li:hover::before {
  width: 0.5rem;
  height: 100%;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
}

.global-menu__icons li:focus svg, .global-menu__icons li:hover svg {
  fill: #FFFFFF;
}

.global-menu__icons li:focus a, .global-menu__icons li:hover a {
  color: #FFFFFF;
}

.global-menu__icons li.active::before {
  height: 100%;
  width: 0.5rem;
  background-color: #FFFFFF;
  position: absolute;
}

.global-menu__icons li.active svg {
  fill: #FFFFFF;
}

.global-menu__icons li.active a {
  color: #FFFFFF;
}

.global-menu__icons li svg {
  transition: ease 250ms;
  display: inline-block;
  fill: #10313E;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 1rem;
}

.global-menu__icons li p {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: ease 250ms;
}

.global-menu__icons li a {
  display: block;
  padding: 1rem 0;
  color: #000000;
}

.global-menu__icons.toggled {
  display: block;
}

.menu-button {
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  padding: 1rem;
  display: inline-block;
}

.menu-button::after {
  content: "☰";
  margin-left: 1rem;
  color: #FFFFFF;
}

.menu-button:hover {
  color: #e6e5e5;
}

.menu-button.toggled::after {
  content: "×";
}

@media (min-width: 47.5em) {
  .global-menu__icons {
    display: flex;
    border-top: 0;
  }
  .global-menu__icons li:focus::before, .global-menu__icons li:hover::before {
    height: 0.5rem;
    width: 100%;
  }
  .global-menu__icons li.active::before {
    height: 100%;
    width: 0.5rem;
  }
  .global-menu__icons li svg {
    display: block;
    margin: 2rem 0;
  }
  .global-menu__icons li p {
    display: none;
  }
  .global-menu__icons li a {
    padding: 0 1rem;
  }
  .global-menu__icons.toggled {
    display: flex;
  }
}

@media (min-width: 80em) {
  .global-menu {
    width: 6.5rem;
    height: auto;
  }
  .global-menu__logo {
    border-bottom: 1px solid #10313E;
  }
  .global-menu__icons {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: block;
    margin: 1rem 0;
  }
  .global-menu__icons li {
    width: 100%;
    margin: 0 0 1rem;
  }
  .global-menu__icons li:focus::before, .global-menu__icons li:hover::before {
    height: 100%;
    width: 0.5rem;
    bottom: 0;
    left: 0;
  }
  .global-menu__icons li a {
    display: block;
    text-align: center;
  }
  .global-menu__icons li svg {
    display: inline-block;
    margin: 1rem 0;
  }
  .global-menu__icons.toggled {
    display: block;
  }
}

.icon {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  line-height: 0;
}

.icon.icon--close {
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #CC1638;
  line-height: 0.0625rem;
}

.icon.icon--close svg {
  fill: #CC1638;
}

.icon.icon--tick {
  cursor: pointer;
  line-height: 0.0625rem;
}

.icon.icon--tick svg {
  fill: #7ED321;
}

.icon.icon--close__negative {
  position: absolute;
  left: -15px;
  top: 15.5px;
  display: block;
}

.icon.icon--clear {
  border: 1px solid #CED8DB;
}

.icon.icon--clear.active {
  background-color: #185B76;
  border: 1px solid #185B76;
}

.icon.icon--clear.active svg {
  fill: #FFFFFF;
}

.icon.icon--clear svg {
  fill: #185B76;
}

.lightbox {
  position: fixed;
  z-index: 9999;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 22, 42, 0.6);
}

.lightbox .lightbox__content {
  position: relative;
  z-index: 9999;
  background: #FFFFFF;
  padding: 1.25rem;
  border: 1px solid #CED8DB;
  border-radius: 4px;
  max-width: 43.75rem;
  float: none;
  margin: 1rem auto;
}

.lightbox .lightbox__content .white-block {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  box-shadow: none;
}

.lightbox .lightbox__close.icon--close {
  right: 0.125rem;
  top: 0.125rem;
  left: auto;
}

.pretty-lists {
  list-style: none;
  list-style-image: url("../../svg/bullet-blue.svg");
}

.pretty-lists .pretty-lists {
  padding-top: .5rem;
}

.pretty-lists li {
  font-size: 0.875rem;
  color: #5C6163;
  position: relative;
  padding-bottom: 0.5rem;
}

.pretty-lists li > a,
.pretty-lists li > p {
  padding-left: 0.5rem;
  margin: 0;
  position: relative;
  top: 1px;
}

.pretty-lists li a:not(:hover), .pretty-lists li p:not(:hover) {
  font-size: inherit;
  color: inherit;
}

.pretty-lists li .pad-lt {
  padding-left: 1rem;
}

.status-lists {
  list-style: none;
}

.status-lists ul {
  list-style: none;
}

.status-lists .icon__svg {
  fill: #7ED321;
}

.main-menu {
  width: 100%;
  height: 3.5625rem;
}

.main-menu__button {
  color: #FFFFFF;
  padding: 1rem;
  cursor: pointer;
}

.main-menu__button p {
  color: #FFFFFF;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.125rem;
}

.main-menu__button p::after {
  content: "+";
  float: right;
}

.main-menu__button.toggled p::after {
  content: "-";
}

.main-menu__sub-menu-label {
  fill: #FFFFFF;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.main-menu__sub-menu {
  margin-top: 1.25rem;
}

.main-menu__sub-menu-toggle {
  cursor: pointer;
}

.main-menu__sub-menu-toggle svg {
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 50%;
  position: relative;
  right: -0.25rem;
}

.main-menu__sub-menu-toggle__minus {
  display: none;
}

.main-menu__sub-menu-toggle__minus svg {
  background: #1FAAE2;
}

.main-menu__sub-menu-toggle__plus svg {
  background: #00162A;
}

.main-menu__navigation > ul {
  margin-bottom: 0;
}

.main-menu__navigation > ul > li {
  border-bottom: 1px solid rgba(0, 22, 42, 0.3);
  padding: 0.9375rem;
}

.main-menu__navigation li:focus > a, .main-menu__navigation li:hover > a, .main-menu__navigation li.active > a {
  font-weight: bold;
  background-color: #E9EFF1;
  color: #185B76;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: -1rem;
  padding-left: 1rem;
}

.main-menu__navigation li:focus > a .has-tip, .main-menu__navigation li:hover > a .has-tip, .main-menu__navigation li.active > a .has-tip {
  font-weight: bold;
}

.main-menu__navigation a {
  color: #E9EFF1;
  text-decoration: none;
  font-size: 0.875rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin: 0.3125rem 0;
  transition: ease 250ms;
  letter-spacing: 1.17px;
  display: block;
}

.main-menu__navigation--no-sub-menu > ul > li {
  padding: 0 0.9375rem;
}

.main-menu--two-items li:focus span, .main-menu--two-items li:hover span, .main-menu--two-items li.active span {
  color: #00162A !important;
}

.main-menu--two-items li:focus small, .main-menu--two-items li:hover small, .main-menu--two-items li.active small {
  color: #5C6163 !important;
}

.main-menu--two-items a {
  font-size: 1rem;
}

.main-menu .menu-container {
  display: none;
}

.main-menu .menu-container.toggled {
  display: block;
}

.main-menu.toggled {
  height: auto;
}

@media (min-width: 80em) {
  .main-menu {
    height: auto;
    width: 13.3125rem;
  }
  .main-menu__button {
    display: none;
  }
  .main-menu .menu-container {
    display: block;
  }
}

.sidebar {
  display: none;
  box-shadow: 0 2px 10px 0 rgba(25, 63, 78, 0.25);
}

.sidebar > .row {
  border-bottom: 1px solid #E9EFF1;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.sidebar-container {
  padding: 1rem;
}

@media (min-width: 80em) {
  .sidebar {
    display: block;
    width: 15.3125rem;
    padding: 1rem;
  }
  .sidebar .profile-block__button {
    margin-bottom: 1rem;
  }
}

table {
  max-width: calc(100vw - 54px);
  overflow-x: scroll;
  display: block;
}

table .spacing-large {
  display: block;
  width: 12.5rem;
}

.table-scroll {
  display: block;
  max-width: calc(100vw - 96px);
}

.table-scroll.content-table table {
  min-width: 100%;
  border-collapse: collapse;
  display: table;
  max-width: 100%;
}

.table-scroll.content-table table thead {
  border: 0;
  background: transparent;
}

.table-scroll.content-table table thead th {
  border-bottom: 0.0625rem dotted #CED8DB;
}

.table-scroll.content-table table tbody {
  border: 0;
}

.table-scroll.content-table table tbody tr {
  background: transparent;
}

.table-scroll.content-table table tbody tr.even {
  background: #E9EFF1;
}

.table-scroll.content-table table tbody td {
  border: 0;
}

.table-scroll.content-table table tbody td.table__bool svg {
  fill: #1FAAE2;
}

.table-scroll.content-table table tbody td p {
  margin-bottom: 0;
}

.table-scroll.content-table table td,
.table-scroll.content-table table th {
  font-size: 0.875rem;
  color: #5C6163;
  display: block;
  text-transform: none;
  letter-spacing: 1.17px;
  padding-bottom: 0.5rem;
  display: table-cell;
  font-weight: normal;
  padding: 0.5rem 0.75rem;
}

.table-scroll.finance-table {
  max-width: calc(100% + 34px);
  width: calc(100% + 34px);
  margin-left: -2.125rem;
}

.table-scroll.finance-table thead tr th {
  padding: 0;
  border-bottom: 0;
}

.table-scroll.finance-table thead tr th:first-of-type {
  min-width: 2.25rem;
}

.table-scroll.finance-table thead tr th label {
  padding-bottom: 0;
}

.table-scroll.finance-table tbody tr:first-of-type td {
  padding-top: 0.5rem;
  min-width: 2.25rem;
}

.table-scroll.finance-table tbody tr td {
  padding: 0.375rem;
  position: relative;
}

.table-scroll.finance-table tbody tr .input-div {
  padding: 0.875rem 0.375rem;
}

.table-scroll.finance-table tbody tr .icon.icon--close__negative {
  position: static;
  top: inherit;
  left: inherit;
  padding: 0.1875rem;
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
}

.table-scroll.finance-table tbody tr .icon.icon--close__negative svg {
  width: 0.5rem;
  height: 0.5rem;
}

.table-scroll.finance-table tbody tr .icon-col {
  padding: 0 0.25rem 0;
}

.table-scroll.finance-table tbody tr .financial-information__align .input-div {
  border: 1px solid #CED8DB;
  border-radius: 4px;
  min-height: 3.125rem;
}

.table-scroll.finance-table tbody tr .financial-information__align .input-div svg {
  margin-top: 0.375rem;
}

@media print, screen and (min-width: 47.5em) {
  .table-scroll.finance-table tbody tr .input-div {
    padding: 1rem;
  }
  .table-scroll.finance-table tbody tr .financial-information__align {
    border: 0;
  }
  .table-scroll.finance-table tbody tr .financial-information__align .input-div {
    border: 0;
  }
}

ul.tabs .tabs-title:focus > a[aria-selected='false'], ul.tabs .tabs-title:hover > a[aria-selected='false'] {
  color: #1FAAE2;
  background: #FFFFFF;
}

ul.tabs .tabs-title > a {
  transition: ease 250ms;
  text-transform: uppercase;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

ul.tabs .tabs-title > a[aria-selected='false'] {
  background: #CED8DB;
}

ul.tabs + .tabs-content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 3px 7px 3px -5px rgba(25, 63, 78, 0.25);
}

.pagination li {
  padding: 0.625rem;
}

.pagination li a,
.pagination li span {
  display: block;
  padding: 0 0.3125rem;
}

.article {
  position: relative;
  box-shadow: 0 3px 2px 0 rgba(36, 36, 36, 0.3);
  border-radius: 4px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
}

.article > a {
  overflow: hidden;
  display: block;
}

.article__notification {
  position: absolute;
  top: 0;
  right: 0;
  background: #10313E;
  color: #FFFFFF;
  z-index: 2;
  font-size: 0.75rem;
  padding: 0.3125rem 0.625rem;
}

.article.article--recommended {
  border: 3px solid #185B76;
  box-shadow: none;
  position: relative;
}

.article .article__banner {
  position: relative;
  background-size: cover !important;
  min-height: 11rem;
}

.article .article__banner::before {
  background-color: rgba(5, 24, 68, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.article .article__banner--1 {
  background: url("/storage/banners/small/1.jpeg") no-repeat center center;
}

.article .article__banner--2 {
  background: url("/storage/banners/small/2.jpeg") no-repeat center center;
}

.article .article__banner--3 {
  background: url("/storage/banners/small/3.jpeg") no-repeat center center;
}

.article .article__banner--4 {
  background: url("/storage/banners/small/4.jpeg") no-repeat center center;
}

.article .article__banner--5 {
  background: url("/storage/banners/small/5.jpeg") no-repeat center center;
}

.article .article__banner--6 {
  background: url("/storage/banners/small/6.jpeg") no-repeat center center;
}

.article .article__banner--7 {
  background: url("/storage/banners/small/7.jpeg") no-repeat center center;
}

.article .article__banner--8 {
  background: url("/storage/banners/small/8.jpeg") no-repeat center center;
}

.article .article__banner--9 {
  background: url("/storage/banners/small/9.jpeg") no-repeat center center;
}

.article .article__banner--10 {
  background: url("/storage/banners/small/10.jpeg") no-repeat center center;
}

.article .article__banner--11 {
  background: url("/storage/banners/small/11.jpeg") no-repeat center center;
}

.article .article__banner--12 {
  background: url("/storage/banners/small/12.jpeg") no-repeat center center;
}

.article .article__banner--13 {
  background: url("/storage/banners/small/13.jpeg") no-repeat center center;
}

.article .article__banner--14 {
  background: url("/storage/banners/small/14.jpeg") no-repeat center center;
}

.article .article__banner--15 {
  background: url("/storage/banners/small/15.jpeg") no-repeat center center;
}

.article .article__banner--16 {
  background: url("/storage/banners/small/16.jpeg") no-repeat center center;
}

.article .article__banner--17 {
  background: url("/storage/banners/small/17.jpeg") no-repeat center center;
}

.article .article__logo {
  position: relative;
  top: -48px;
}

.article .article__logo img {
  display: block;
  margin: 0 auto;
}

.article .article__content {
  position: relative;
  top: -18px;
}

.article .article__content h2 {
  font-size: 1.75rem;
}

.article .article__content .article__sub-heading {
  font-size: 0.875rem;
}

.article .article__meta {
  border-top: 1px solid #E9EFF1;
}

.filters .filters__list a {
  position: relative;
  color: #FFFFFF;
  transition: ease 250ms;
}

.filters .filters__list a::before {
  position: absolute;
  left: 0;
  border-radius: 2px;
  margin-right: 0.625rem;
  background: #E9EFF1;
  height: 1rem;
  width: 1rem;
  margin-top: 0.28125rem;
  margin-bottom: 0.28125rem;
  display: block;
  float: left;
}

.filters .filters__list a:focus, .filters .filters__list a:hover {
  color: #1FAAE2;
}

.filters .filters__list a.active::before {
  background: #1FAAE2 url("../../svg/form-tick-white.svg") center center no-repeat;
  background-size: 7px 5px;
}

.filters .filters__list a > span {
  margin-top: 0.28125rem;
  position: relative;
  left: 1.875rem;
  width: calc(100% - 30px);
  word-wrap: break-word;
  display: block;
}

.financial-information .financial-information__body p {
  margin-bottom: 0;
  color: #5C6163;
}

.financial-information .financial-information__align {
  margin: 0.9375rem 0;
  position: relative;
}

.financial-information .financial-information__checkbox .form-group {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.note {
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #CED8DB;
}

.note:last-child {
  border-bottom: 0;
}

.tab-article:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.social-login a {
  padding: 1rem 1rem 1rem 0.5rem;
  border: 0.125rem solid #FFFFFF;
  border-radius: 0.25rem;
  line-height: 2.875rem;
}

.social-login--google {
  color: #FFFFFF;
  font-weight: bold;
}

.social-login--google::before {
  content: url(/storage/social/btn_google_light_normal_ios.svg);
  height: 2.875rem;
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}

.social-login--google:focus, .social-login--google:hover {
  color: #1779ba;
  border: 0.125rem solid #1779ba;
  background: #FFFFFF;
}

.social-login--linkedin {
  color: #FFFFFF;
  font-weight: bold;
  border: 0.125rem solid #FFFFFF;
  border-radius: 0.25rem;
}

.social-login--linkedin::before {
  background: url(/storage/social/In-2CRev-48px-R.png) left no-repeat;
  background-size: cover;
  content: " ";
  height: 2.875rem;
  width: 2.875rem;
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}

.social-login--linkedin:focus, .social-login--linkedin:hover {
  color: #1779ba;
  border: 0.125rem solid #1779ba;
  background: #FFFFFF;
}

.profile-block__details .button {
  margin: 0.5rem 0;
}

.service-plan {
  border-radius: 0.25rem;
  padding: 0 0 1rem;
  margin-bottom: 0;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-plan__title {
  background-color: #1FAAE2;
  padding: 1.25rem 0.9375rem;
  color: #FFFFFF;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.service-plan__title--plain {
  background-color: #185B76;
  padding: 2rem 0.9375rem;
}

.service-plan__highlight {
  font-size: 0.875rem;
}

.service-plan__heading {
  margin: 0;
  line-height: 1;
}

.service-plan__features {
  padding: 1.3125rem 0.9375rem;
}

.service-plan__features p {
  margin: 0;
  font-size: 0.875rem;
  color: #5C6163;
}

.service-plan__advisory {
  padding: 1.125rem 0.9375rem;
  border-top: 1px dotted #D8D8D8;
  border-bottom: 1px dotted #D8D8D8;
  color: #80A0C4;
  font-size: 0.875rem;
}

.service-plan__advisory p {
  margin: 0;
  color: inherit;
  font-size: inherit;
}

.service-plan__price-monthly {
  color: #5C6163;
  padding: 1rem 0.9375rem 0;
}

.service-plan__price-monthly p {
  font-size: 0.875rem;
  margin: 0;
}

.service-plan__price-monthly span {
  font-size: 1.5rem;
}

.service-plan__price-yearly {
  color: #5C6163;
  margin-bottom: 0.125rem;
}

.service-plan__button {
  padding: 0 0.9375rem;
}

.service-plan__matrix {
  color: #5C6163;
  display: table;
  padding-top: 0.75rem;
}

.service-plan__matrix svg {
  fill: #415264;
}

.service-plan__matrix svg.icon__cross {
  transform: rotate(45deg);
}

.service-plan__matrix thead {
  background: none;
  border: 0;
}

.service-plan__matrix thead h4 {
  color: #5C6163;
}

.service-plan__matrix tbody {
  border: 0;
}

.service-plan__matrix tbody tr {
  background-color: transparent;
}

.service-plan__matrix tbody tr:last-child td {
  border-bottom: 0;
}

.service-plan__matrix tbody td {
  text-align: right;
  border-bottom: 1px dotted #D8D8D8;
  border-spacing: 0;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
}

.service-plan__matrix--text-left {
  text-align: left !important;
}

.social-links svg {
  fill: #1FAAE2;
  margin-right: 0.5rem;
}

.title .title__content {
  margin-top: 1.71875rem;
}

.title img {
  margin: 0 auto;
  display: block;
}

@media print, screen and (min-width: 47.5em) {
  .title .title__content {
    margin-top: 0;
  }
  .title img {
    margin: 0;
    display: inline-block;
  }
}

.croppie-container {
  width: 100%;
  height: 100%;
}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.croppie-container .cr-resizer {
  z-index: 2;
  box-shadow: none;
  pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: '';
}

.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px;
}

.croppie-container .cr-original-image {
  display: none;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  touch-action: none;
}

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */
  /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;
  /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}

/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.cr-rotate-controls button {
  border: 0;
  background: none;
}

.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}

.cr-rotate-l i:before {
  content: '↺';
}

.cr-rotate-r i:before {
  content: '↻';
}
