.scrollable-y {
    overflow-x: hidden;
}
 
.scrollable-x {
    overflow-y: hidden;
}

.global-menu {
    background-image: radial-gradient(0% 45%, #1782AD 0%, $theme-blue__light 100%);
    background-image: -webkit-radial-gradient(0% 45%, #1782AD 0%, $theme-blue__light 100%);
    width: 100%;

    &__logo {
        padding: rem-calc(17) 0;
        min-width: rem-calc(104);

        svg {
            display: block;
            margin: 0 auto;
            fill: $white;
        }
    }

    &__icons {
        border-top: 1px solid rgba($black, .25);
        list-style: none;
        margin: 0;
        padding: 0;
        display: none;
        justify-content: flex-end;

        li {
            position: relative;

            @include before {
                transition: $theme-transition-default;
            }

            @include hocus {
                @include before {
                    width: rem-calc(8);
                    height: 100%;
                    background-color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                svg {
                    fill: $white;
                }

                a {
                    color: $white;
                }
            }

            @include active {
                @include before {
                    height: 100%;
                    width: rem-calc(8);
                    background-color: $white;
                    position: absolute;
                }

                svg {
                    fill: $white;
                }

                a {
                    color: $white;
                }
            }

            svg {
                transition: $theme-transition-default;
                display: inline-block;
                fill: $theme-blue__dark;
                cursor: pointer;
                vertical-align: middle;
                margin: 0 rem-calc(16);
            }

            p {
                display: inline-block;
                vertical-align: middle;
                margin: 0 0 0 rem-calc(16);
                text-transform: uppercase;
                font-weight: bold;
                transition: $theme-transition-default;
            }

            a {
                display: block;
                padding: rem-calc(16) 0;
                color: $black;
            }
        }

        &.toggled {
            display: block;
        }
    }
}

.menu-button {
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: rem-calc(1);
    padding: rem-calc(16);
    display: inline-block;

    &::after {
        content: "☰";
        margin-left: rem-calc(16);
        color: $white;
    }

    &:hover {
        color: darken($white, 10);
    }

    &.toggled {
        &::after {
            content: "×";
        }
    }
}

@media #{breakpoint(medium)} {
    .global-menu {
        &__icons {
            display: flex;
            border-top: 0;

            li {
                @include hocus {
                    @include before {
                        height: rem-calc(8);
                        width: 100%;
                    }
                }

                @include active {
                    @include before {
                        height: 100%;
                        width: rem-calc(8);
                    }
                }

                svg {
                    display: block;
                    margin: rem-calc(32) 0;
                }

                p {
                    display: none;
                }

                a {
                    padding: 0 rem-calc(16);
                }
            }

            &.toggled {
                display: flex;
            }
        }
    }
}

@media #{breakpoint(large)} {
    .global-menu {
        width: rem-calc(104);
        height: auto;

        &__logo {
            border-bottom: 1px solid $theme-blue__dark;
        }


        &__icons {
            padding-left: 0;
            padding-right: 0;
            position: relative;
            display: block;
            margin: rem-calc(16) 0;

            li {
                width: 100%;
                margin: 0 0 rem-calc(16);

                @include hocus {
                    @include before {
                        height: 100%;
                        width: rem-calc(8);
                        bottom: 0;
                        left: 0;
                    }
                }

                a {
                    display: block;
                    text-align: center;
                }

                svg {
                    display: inline-block;
                    margin: rem-calc(16) 0;
                }
            }

            &.toggled {
                display: block;
            }
        }
    }
}
