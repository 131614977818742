.button {
    text-transform: none;
    border-radius: rem-calc(4);
    display: block;
    text-decoration: none;
    cursor: pointer;
    transition: $theme-transition-default;

    &:focus {
        outline: 0;
    }

    &--shrink {
        width: auto !important;
        display: inline-block;
    }

    > span {
        display: inherit;
        padding: $global-margin;
        letter-spacing: 2.44px;
        font-size: rem-calc(13);
        line-height: rem-calc(13);
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }

    svg {
        margin: $global-margin;
    }

    .button--letter-spacing-small {
        letter-spacing: 1.17px !important;
    }

    &--large {
        font-size: 1rem;
        font-weight: bold;
    }

    &--clear {
        @include button-clear;

        @include hocus {
            @include button-blue;
        }
    }

    &--blue {
        @include button-blue;

        @include hocus {
            @include button-blue-extra-10;
        }
        margin-bottom:10px;
    }

    &--dark-blue {
        @include button-dark-blue;

        @include hocus {
            @include button-blue;
        }
    }//not fine

    &.button-dark-blue-inverted {
        @include button-dark-blue-inverted;

        @include hocus {
            @include button-dark-blue;
        }
    }

    &--red {
        @include button-red;

        @include hocus {
            @include button-clear;
        }
    }

    &--green {
        @include button-green;

        @include hocus {
            @include button-clear;
        }
    }

    &--grey {
        @include button-grey;

        @include hocus {
            @include button-blue;
        }
    }

    &--dotted-blue {
        border: 1px dotted $theme-blue;
        color: $theme-blue;

        @include disabled {
            background-color: darken($theme-blue, 10);
        }

        svg {
            fill: $theme-blue;
        }
    }

    &--svg {
        text-align: left;

        span {
            display: inline-block;
        }

        svg {
            float: right;
        }
    }

    &--svg-blue {
        svg {
            fill: $theme-blue;
        }
    }

    &--svg-dark-blue {
        svg {
            fill: $theme-blue__light;
        }
    }

    &--svg-edit,
    &--svg-trash  {
        width: 100%;
        
        svg {
            margin-top: rem-calc(11);
            margin-bottom: rem-calc(11);
        }
    }

    &--svg-watch {
        svg {
            margin-top: rem-calc(14.5);
            margin-bottom: rem-calc(14.5);
        }
    }

    &--svg-plus {
        svg {
            margin-top: rem-calc(13.5);
            margin-bottom: rem-calc(13.5);
        }
    }
    
    &.button--svg-envelope {
        svg {
            margin: rem-calc(10.5) 0.5rem;
        }
    }

    &--small {
        > span {
            padding-top: rem-calc(8.5);
            padding-bottom: rem-calc(8.5);
        }

        svg {
            margin-top: rem-calc(10);
            margin-bottom: rem-calc(10);
        }
    }

    &--text-center {
        text-align: center;

        svg {
            float: none;
            margin: 0;
        }
    }

    &--mob-full {
        width: 100%;
        margin-top: rem-calc(16);
    }

    &--full-width {
        width: 100%;

        > span {
            text-align: center;
            display: block;
            width: 100%;
        }
    }

    &--grey.button--svg.button--small {
        float: right;
    }
    
    &--full-width{
        width: 100%;
    }
}

.unpublish-form{
    .button--blue{width: 100%;}
}


@include breakpoint(medium) {
    .mandate-button-row {
        padding-top: rem-calc(16);
    }

    .button {
        &--mob-full {
            // width: auto;
            margin-top: 0;
        }
    }
}

@include breakpoint(medium down) {
    .button {
        &--full-width-small {
            width: 100% !important;
        }
    }
}

.complete-reg-form {
    .button {
        text-transform: none;
        
        span{
            letter-spacing: 0;
            font-size: rem-calc(16);
        }
    }
    
    svg{
        margin: 0;
    }
}
.login-form{
    .button{
        span{
            letter-spacing: 0;
            text-transform: none;
            font-size: rem-calc(16);
        }
    }
}

.complete-reg-form .button span span {
    letter-spacing: 0;
    font-size: 0.9rem;
    display: block;
    float: left;
    line-height: 18px;
    margin: 0;
    padding: 0px 0px 10px 5px;
}
.complete-reg-form .button span.arrowsvg {
    line-height: 18px;
    margin-top: 2px;
    margin-right: 10px;
}
