.form-group {
    margin-bottom: $global-margin;

    &.has-error {
        input[type="text"],
        input[type="number"],
        input[type="date"],
        input[type="email"],
        input[type="password"],
        textarea,
        select,
        .select {
            border: 1px solid $theme-red;
        }
    }

    .form-group__error {
        padding-top: 0;

        span {
            font-size: 13px;
            background-color: transparent;
            color: red;
            padding: 0px;

            strong {
                font-weight: normal;
            }
        }
    }

    .form-group__prefix {
        span {
            background-color: $theme-font-white;
            text-align: center;
            display: block;
            font-size: 1rem;
            padding: rem-calc(14.5) 0;
            line-height: 1.3rem;
            outline: 0;
            height: rem-calc(49);
            border-top-left-radius: $theme-border-radius;
            border-bottom-left-radius: $theme-border-radius;
        }

        + .columns {
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .form-group__search {

        input[type="search"] {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: rem-calc(49);
        }

        .form-group__search-button {
            svg {
                fill: $theme-blue;
            }

            padding: rem-calc(9.5);
            background-color: $white;
            border: 1px solid $theme-font-white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: rem-calc(49);
        }
    }

    .form-group__file-preview {
        margin-top: -9.5px;
    }

    .form-group__file-name {
        p {
            margin: rem-calc(5) 0;
            text-transform: none;
            font-size: rem-calc(14);
            color: $theme-blue;
            letter-spacing: 1.17px;
        }
    }

    .radio {
        label {
            padding-top: .4rem;
        }
    }

    &.has-erros {
        input[type="text"],
        input[type="number"],
        input[type="date"],
        textarea {
            border: 1px solid red;
        }
    }

    label {
        @include label-styles;
	span{
            font-weight: normal;
            text-decoration: none;
        }
    }

    input {
        height: rem-calc(49);
    }

    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="checkbox"],
    input[type="email"],
    input[type="password"],
    input[type="radio"],
    input[type="search"],
    textarea,
    select,
    .select {
        @include form-input-styles;
        width: 100%;

        &:focus {
            outline: 0;
        }

        &:disabled {
            background: $theme-font-white;
        }
    }

    input[type="search"] {
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }

    select,
    input[type="checkbox"],
    input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
    }

    input[type="radio"] {
        position: relative;
        height: 32px;
        width: 32px;
        padding: 0;
        border-radius: 50%;

        @include checked {
            @include after {
                position: absolute;
                width: 16px;
                height: 16px;
                width: 50%;
                height: 50%;
                background: $theme-blue;
                border-radius: 50%;
                margin-top: rem-calc(8);
                margin-left: rem-calc(8);
            }
        }
    }

    input[type="checkbox"] {
        position: relative;
        height: 32px;
        width: 32px;
        padding: 0;

        @include checked {
            border: 1px solid $theme-blue;

            @include after {
                background: $theme-blue url('../../svg/form-tick-white.svg') center center no-repeat;
                background-size: 17px 15px;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }

    select,
    .select {
        padding-right: rem-calc(40px);
    }

    .select-container {
        position: relative;

        @include after {
            pointer-events:none;
            position: absolute;
            border-left: 1px solid $theme-font-white;
            display: block;
            right: 0;
            top: 0;
            width: rem-calc(40px);
            height: 100%;
            background: url('../../svg/form-arrow-blue.svg') center center no-repeat;
            background-size: 14px 9px;
        }
    }

    textarea {
        min-height: 150px;
        max-height: 300px;
    }

    .input-div {
        @include form-input-styles;
    }
    
    .text-transform-none{
        text-transform:none;
    }
}

.select-search {
    position: relative;

    input[type="search"] {
        border-radius: 0;
        border: 5px solid $theme-font-white;
    }

    .select {
        cursor: pointer;
    }

    ul {
        list-style: none;
        margin: 0;
        max-height: 230px;
        overflow: scroll;
    }       

    &__options {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: $white;
        z-index: 3;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .2);
    }

    li {
        @include form-input-styles;
        width: 100%;
        border-radius: 0;
        border-top: 0;
        cursor: pointer;
        transition: padding ease 250ms;

        @include hocus {
            padding-left: 40px;
            text-decoration: underline;
        }
    }
}

.complete-reg-form{
    .form-group {
        label{
            text-transform: none;
        }
    }
}
.login-form{
    .form-group{
        label{
            text-transform: none;
        }
    }
}
