@import url('https://fonts.googleapis.com/css?family=Poppins');

$white: #FFFFFF;
$black: #000000;

$theme-black: #00162A;

$theme-grey: #E9EFF1;
$theme-grey__dark: #5C6163;
$theme-gray-alt: #D8D8D8;

$theme-green: #7ED321;

$theme-right-angle: polygon(0 0, 100% 0, 100% 87%, 0 100%);

$theme-white-box-shadow: 3px 7px 3px -5px rgba(25, 63, 78, 0.25);

$theme-blue__light: #185B76;
$theme-blue: #1FAAE2;
$theme-blue__dark: #10313E;
$theme-blue-alt: #80A0C4;
$theme-blue-alt-2: #415264;

$theme-blue__overlay: #051844;
$theme-blue__overlay_light: #213E6F;

$theme-dark-border: #091F27;

$theme-red: #CC1638;

$theme-orange: #F6A623;

$theme-yellow: #FFF427;

$theme-font-white: #CED8DB;

$theme-z-index-max: 9999;
$theme-z-index-large: 999;
$theme-z-index-medium: 99;

$font-primary: 'Poppins', sans-serif;

$font-size-medium: 24px;

$theme-border-radius: 4px;

$theme-transition-default: ease 250ms;
