.icon {
    display: inline-block;
    padding: 10px;
    border-radius: $theme-border-radius;
    line-height: 0;

    &.icon--svg {

    }

    &.icon--close {
        cursor: pointer;
        padding: 5px;
        border-radius: 50%;
        border: 1px solid $theme-red;
        line-height: rem-calc(1);

        svg {
            fill: $theme-red;
        }
    }

    &.icon--tick {
        cursor: pointer;
        line-height: rem-calc(1);

        svg {
            fill: $theme-green;
        }
    }

    &.icon--close__negative {
        position: absolute;
        left: -15px;
        top: 15.5px;
        display: block;
    }

    &.icon--clear {
        border: 1px solid $theme-font-white;

        @include active {
            background-color: $theme-blue__light;
            border: 1px solid $theme-blue__light;

            svg {
                fill: $white;
            }
        }

        svg {
            fill: $theme-blue__light;
        }
    }
}
