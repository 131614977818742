.lightbox {
    position: fixed;
    z-index: $theme-z-index-max;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($theme-black, .6);

    .lightbox__content {
        position: relative;
        z-index: $theme-z-index-max;
        background: $white;
        padding: rem-calc(20);
        border: 1px solid $theme-font-white;
        border-radius: $theme-border-radius;
        max-width: rem-calc(700);
        float: none;
        margin: $global-margin auto;
        //max-height: calc(100vh - 100px);

        .white-block {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
            box-shadow: none;
        }
    }

    .lightbox__close {
        &.icon--close {
            right: rem-calc(2);
            top: rem-calc(2);
            left: auto;
        }
    }
}
